import React from 'react'
import "./NotFound.css"

function NotFound() {
  return (
    <div>
        <div className='nf_wrapper'>
            <p className='nf_title'>Page Not Found</p>
            <p className='nf_text'> This page doesn't exist, try reaching out to suport!</p>
            <div>
                <img
                className='nf_image'
                src='https://c.tenor.com/HpkBYpC_0nkAAAAC/%D9%85%D8%A7%D8%B0%D8%A7-what.gif'
                />
            </div>

        </div>
    </div>
  )
}

export default NotFound