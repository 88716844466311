import React, {useState, useEffect} from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import {useParams} from "react-router-dom";
import "./Campaign.css"
import Accordion from '@mui/material/Accordion';
import { Button, Grid, Hidden, TextareaAutosize } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileSaver from 'file-saver';

function Campaign({layout}) {

    const [submissions, setSubmissions] = useState([])
    const [campaign, setCampaign] = useState({})
    const [loaded, setLoaded] = useState(false)
    const axiosPrivate = useAxiosPrivate()
    const {hello_id} = useParams();

    useEffect(() => {
        axiosPrivate.get(`/getSubmissions/${hello_id}`).then((response) =>{
            setSubmissions(response?.data?.entries)
            setCampaign(response?.data?.campaign)
            setLoaded(true)
            console.log(response?.data?.entries)
        }).catch((err)=>{
            console.log(err)
            //window.location.href = "/"
        })
      }, [])

    function downloadImage(file_id){
        console.log(file_id)
        axiosPrivate.get(`/getImage/${file_id}`, {responseType: 'blob'}).then((response) =>{
            console.log(response?.data)
            const file = new Blob([response.data], {type: response.headers["content-type"]})
            FileSaver.saveAs(file, file_id)
        }).catch((err)=>{
            // console.log(err)
        })
    }

    function delete_entry(entry_id, idx){
        axiosPrivate.delete(`/entry/${entry_id}`).then((response) =>{
            console.log(response?.data)
            const vals = [...submissions]
            vals.splice(idx,1)
            setSubmissions(vals)
            
        }).catch((err)=>{
            console.log(err)
        })
    }

  return (
    <div className='submissions_wrapper'>
        { (!loaded) ? <><div className="loader-container"><div className="spinner"></div></div></> 
        : (<>
        <p className='campaign_title_singular'>{campaign?.name}</p>
        <p style={{textAlign:"center"}}>
        {(submissions.length === 0) ? <><strong>There are no submission right now, try sharing the submission link!</strong><br/><img className="sad_image" src="https://thumbs.dreamstime.com/b/very-sad-cartoon-penguin-illustration-lonely-followed-around-dark-cloud-167852203.jpg" /></>
        : <strong>{`There are currently ${submissions.length}/100 submissions`}</strong>}
        </p>
            {submissions.map((sub, i) => {
                return(
                <div key={i}>
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography><strong>{`${i+1}.`}</strong> {(sub?.fields[0]?.value) ? sub?.fields[0]?.value : "No Name Given"}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>  
                            <Grid container className='campaign_form_container campaign_verify_page' >
                                <Hidden only="xs">
                                    <Grid item xs={12} sm={6} style={{marginTop: "2em"}}>
                                        <strong>Question</strong>
                                    </Grid>
                                    <Grid item xs={8} sm={6} style={{marginTop: "2em"}}>
                                        <strong>Response</strong>
                                    </Grid>
                                </Hidden>
                                <Hidden smUp>
                                    <Grid item xs={12}>
                                        <a><strong>Prompts:</strong></a>
                                    </Grid>
                                </Hidden>
                                {campaign?.fields.map((row_val, idx) => 
                                <React.Fragment key={idx}>
                                    <Grid item xs={12} sm={6} className="campaign_top_of_row">
                                        <TextareaAutosize 
                                        maxLength={"128"}
                                        className="campaign_auto_resize_disabled" 
                                        placeholder=""
                                        value={(row_val?.text) ? row_val?.text : ""}
                                        style={{width:"85%"}}
                                        name="text"
                                        disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {(row_val.type === "text") ?
                                            <div style={{margin: "auto", textAlign:"center"}}>
                                            <TextareaAutosize 
                                                maxLength={"128"}
                                                className="campaign_auto_resize_disabled" 
                                                placeholder=""
                                                style={{width:"85%", textAlign:"center"}}
                                                value={(sub?.fields[idx].value) ? sub?.fields[idx].value : "" }
                                                name="text"
                                                disabled
                                                />
                                            </div> :
                                            <Button
                                            variant="contained"
                                            style={{marginTop: ".6em"}}
                                            disabled={((sub?.fields[idx].value === null) || (sub?.fields[idx].value === "None"))}
                                            onClick={() => downloadImage(sub?.fields[idx].value)}
                                            >
                                                <strong>Download image</strong>
                                            </Button>
                                        }
                                    </Grid>
                                </React.Fragment>
                                )}
                                <Grid item xs={12}>
                                    <Button
                                    variant="contained"
                                    style={{marginTop: "1em"}}
                                    onClick={() => delete_entry(sub?.id, i)}
                                    color="error"
                                    >
                                        <strong>Delete Entry</strong>
                                    </Button>
                                </Grid>     
                            </Grid>                     
                        </AccordionDetails>
                    </Accordion>
                </div>
            )})}
        </>)}
    </div>
  )
}

export default Campaign;