import React, {useState, useEffect} from 'react'
import './Header.css'
import {BiMenuAltRight} from "react-icons/bi";
import {AiOutlineClose} from "react-icons/ai";
import useRefreshToken from '../../hooks/useRefreshToken';
import axios from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function Header({showFormFunction, isLoggedIn, showLogin}) {

    const axiosPrivate = useAxiosPrivate()

    const refresh = useRefreshToken();
    const [menuOpen, setMenuOpen] = useState(false);
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (size.width > 768 && menuOpen) {
            setMenuOpen(false);
        }
    }, [size.width, menuOpen]);

    function menuToggleHandler() {
        setMenuOpen(!menuOpen)
    }

    function toggler(){
        if (isLoggedIn){
            menuToggleHandler(); 
            showFormFunction();
        }else{
            window.location.href = "/login"
        }
    }

    function logout(){
        axios.get("/logout", {withCredentials: true}).then((res)=> {
            console.log(res);
            showLogin(false); 
            window.location.href="/";
        })
    }

    return (
        <header className='header'>
            <div className='header_content'>
                <h2 className='header_content_logo'>Always Hello</h2>
            <nav className={`header_content_nav ${(menuOpen && size.width < 768) ? "isMenu" : ""}`}>
                <ul className='header_content_nav_ul'>
                    <li className='header_content_nav_li'>
                        <a className="nav_label" href='/'>Home</a>
                    </li>
                    <li className='header_content_nav_li'>
                        <a  className="nav_label" href="/contact">Contact</a>
                    </li>
                    <li 
                    style={{display: (isLoggedIn) ? "block" : "none"}}
                    className='header_content_nav_li'>
                        <a className="nav_label" href="/campaigns">Campaigns</a>
                    </li>
                    <li 
                    style={{display: (isLoggedIn) ? "block" : "none"}}
                    className='header_content_nav_li' 
                    onClick={() => {logout()}}>
                        <a className="nav_label">Logout</a>
                    </li>
                </ul>
                <button 
                onClick={toggler}
                className='header_button'>
                    {(isLoggedIn) ? "Make a Hello" :"Login"}
                </button>
            </nav>
            <div className='header_content_toggle'>
            {!menuOpen ? (
                        <BiMenuAltRight onClick={menuToggleHandler} />
                    ) : (
                        <AiOutlineClose onClick={menuToggleHandler} />
                    )}
            </div>
            </div>
        </header>
  )
}

export default Header