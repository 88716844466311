import { Grid } from '@mui/material'
import React from 'react'

import "./contact.css"

function Contact() {
  return (
    <>
    <div className='contact_wrapper'>
      <p className='contact_title'>
        Contact Us
      </p>
      <p className='contact_text'>
        Hey there! My name is Sean Mangan. I am a software engineer and primary developer for Always Hello. I'm always looking to improve the site so if you are experiencing any technical difficulties please email
        <a href="mailto:alwayshellohelp@gmail.com">{" AlwaysHelloHelp@gmail.com"}</a>.
      </p>
      <div className='desc_stuff contact_center'>
        <p className='contact_text'>
          Always Hello was originally created as a way to gather advice from friends and family members for my sisters graduation party.
          After creating the first prototype I realized it would be easy to expand for anyone to use and create, and viola, Always Hello was formed!
        </p>
        <p 
        className='contact_text'
        >
          <strong>Thanks for stopping by, I am very excited to share this with you all!</strong>
        </p>
          </div>
      </div>
      <div style={{textAlign:"center"}}>
        <img 
        className=" contact_image contact_center"
        src="https://www.seanpmangan.com/static/media/profile_image.9cbc16ec.jpg"/>
        <p style={{marginTop:"0"}}>{"^ Pic of me :)"}</p>
      </div>
    <div style={{textAlign: "center", margin: "auto"}}>
        <p className='contact_text' style={{padding:"0", margin:"0"}}>
        {"If you are interested in reaching out to me personally, you can find my information on my personal site: "}
        <a href='https://www.seanpmangan.com/'><strong className='linker'>{"www.seanpmangan.com"}</strong></a>.
      </p>
      <br/>
      <p className='contact_text' style={{paddingBottom:"1em", margin:"0"}}>
        {"If you use the site and enjoy it, consider buying me a cup of coffee here "}
        <a href='https://www.buymeacoffee.com/seanpmangan'><strong className='linker'>{"Buy Me a Coffee"}</strong></a>.
      </p>
      </div>
    </>
)
}

export default Contact