import React, {useState} from 'react'
import { Button, Snackbar } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';


const CopyButton = ({url, text}) => {
    const [open, setOpen] = useState(false);
  
    const handleClick = () => {
      setOpen(true);
      navigator.clipboard.writeText(url);
    };
  
    return (
      <>
        <Button 
            variant='contained'
            onClick={() => handleClick()}>
                <SendIcon/>
                <strong>{text}</strong>
            </Button>
        <Snackbar
          message="Copied link to clipboard!"
          autoHideDuration={4000}
          onClose={() => setOpen(false)}
          open={open}
        />
      </>
    );
  };

export default CopyButton