import React, { useState } from 'react'
import "./makeHello.css"
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Hidden from '@mui/material/Hidden'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Alert, AlertTitle } from '@mui/material';


function MakeHello({showForm, showFormFunction}) {

    const [fields, setFields] = useState([])
    const [er, setEr] = useState("")
    const [level, setLevel] = useState(0)
    const [campaignName, setCampaignName] = useState("")
    const [campaignGreeting, setCampaignGreeting] = useState("")
    const [campaignGoodbye, setCampaignGoodbye] = useState("")

    const axiosPrivate = useAxiosPrivate()

    function create_new_hello(event){
        event.preventDefault();
        // console.log(fields)
        // console.log(campaignName)
        // console.log(campaignGreeting)
        // console.log(campaignGoodbye)
        const payload = {
            "fields": fields,
            "name": campaignName,
            "greeting": campaignGreeting,
            "goodbye": campaignGoodbye
        }
        console.log(payload)
        axiosPrivate.post("/createHello", payload).then((res)=> {
            console.log(res)
            window.location.href = "/campaigns"
        }).catch((err)=>{
            if(err?.response?.request?.status === 401){
                setEr("You have too many active campaigns. Please delete one before creating another")
            }else{
                setEr("Unexpected error occured, try again in a bit")
            }
            console.log(err)
        })
    }

    function resetFields(){
        setFields([]);
        setLevel(0);
        setCampaignGreeting('')
        setCampaignName('')
        setEr("")
    }

    function handleChangeInput(idx, event, name){
        const vals = [...fields]
        vals[idx][name] = event.target.value
        setFields(vals)
    }

    function handleDelete(idx){
        const vals = [...fields]
        vals.splice(idx,1)
        setFields(vals)
    }

    function handleFirstPageChange(event, name){
        if (name === "campaign_name"){
            setCampaignName(event.target.value)
        }
        if (name === "campaign_greeting"){
            setCampaignGreeting(event.target.value)
        }
        if (name === "campaign_goodbye"){
            setCampaignGoodbye(event.target.value)
        }

    }

    switch(level){
        case 0:
            return(
                <div 
                className={`make_hello_container ${(showForm) ? "faded" : ""}`} 
                style={{display : (showForm) ? "block" : "none"}}
                >
                    <div className={`invisi_block`}/>
                    <div className={`make_form_container ${(showForm) ? "faded_delay" : ""}`}>
                        <form onSubmit={(e) => {e.preventDefault(); setLevel(level+1)}}>
                        <div style={{width:"100%"}}>
                                <div className="close_icon_wrapper">
                                    <CloseIcon 
                                    sx={{fontSize: "5vh"}} 
                                    className="close_icon" 
                                    onClick={function remove() {showFormFunction(); resetFields();}} />
                                </div>
                            </div>
                            <div className='make_hello_wrapper'>
                                <Typography className='make_title' variant="h2">
                                    Create a Hello Campaign
                                </Typography>
                                <Typography className='make_description' variant='h6'>
                                    Lets start by giving this campaign a name and a greeting message for your guests.
                                </Typography>
                                <Grid container className='make_hello_form_container '>
                                    <Grid item xs={12}>
                                        <TextField 
                                        value={campaignName} 
                                        style={{width: "100%"}} 
                                        id="outlined-basic" 
                                        label="Campaign Name" 
                                        variant="outlined"
                                        name="campaign_name"
                                        onChange={(event) => handleFirstPageChange(event, "campaign_name")}
                                        required/>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <TextareaAutosize 
                                            key={"sakldhasldkh"}
                                            maxLength={"256"}
                                            style={{textAlign: "Left"}}
                                            className="make_hello_auto_resize make_hello_text_box" 
                                            placeholder="Greeting Message"
                                            name="campaign_greeting"
                                            value={campaignGreeting}
                                            onChange={(event) => handleFirstPageChange(event, "campaign_greeting")}
                                            />
                                    </Grid>
                                </Grid>
                                <div className='bottom_form'>
                                    <Button
                                    style={{backgroundColor:"cyan", color:"#3b3b3b"}} 
                                    variant="contained"
                                    type="submit"
                                    >
                                        <strong>Continue</strong>
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        case 1:
            return (
                <div 
                className={`make_hello_container ${(showForm) ? "faded" : ""}`} 
                style={{display : (showForm) ? "block" : "none"}}
                >
                    <div className={`invisi_block`}/>
                    <div className={`make_form_container ${(showForm) ? "faded_delay" : ""}`}>
                        <form onSubmit={(e) => {e.preventDefault(); setLevel(level+1)}}>
                            <div style={{width:"100%"}}>
                                <div className="close_icon_wrapper">
                                    <CloseIcon sx={{fontSize: "5vh"}} 
                                    className="close_icon" 
                                    onClick={function remove() {showFormFunction(); resetFields();}} />
                                </div>
                            </div>
                            <div className='make_hello_wrapper'>
                                <Typography className='make_title' variant="h2">
                                    Create a Hello Campaign
                                </Typography>
                                <Typography className='make_description' variant='h6'>
                                    Now lets write some questions for your guests (maximum of 5).
                                </Typography>
        
                                <Grid container className='make_hello_form_container'>
                                    <Hidden only="xs">
                                        <Grid item xs={5}>
                                            <strong>Prompt</strong>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <strong>Type</strong>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <strong>Delete</strong>
                                        </Grid>
                                    </Hidden>
                                        <Grid item xs={12} sm={5}>
                                            <TextareaAutosize 
                                            name="message" 
                                            className="make_hello_auto_resize_disabled" 
                                            value="What is your name?"
                                            disabled
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={5}>
                                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                <InputLabel>type</InputLabel>
                                                <Select disabled defaultValue={"text"} label="Age" className='make_hello_checkbox'>
                                                    <MenuItem value={"text"}>Text</MenuItem>
                                                    <MenuItem value={"image"}>Image</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                                <IconButton disabled aria-label="delete" size="large" onClick={() => null}>
                                                    <DeleteIcon />
                                                </IconButton>
                                        </Grid>
        
                                    {fields.map((row_val, idx) => 
                                    <React.Fragment key={idx}>
                                        <Grid item xs={12} sm={5} className="top_of_row">
                                            <TextareaAutosize 
                                            maxLength={"128"}
                                            className="make_hello_auto_resize" 
                                            placeholder="Enter a prompt here"
                                            value={row_val.text}
                                            name="text"
                                            required
                                            onChange={event => handleChangeInput(idx, event, "text")}
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={5}>
                                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                <InputLabel></InputLabel>
                                                <Select 
                                                defaultValue={"text"} 
                                                value={row_val.type} 
                                                name="type"
                                                className='make_hello_checkbox'
                                                onChange={event => handleChangeInput(idx, event, "type")}
                                                >
                                                    <MenuItem value={"text"}>Text</MenuItem>
                                                    <MenuItem value={"image"}>Image</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} md={2}>
                                            <IconButton aria-label="delete" size="large" onClick={() => handleDelete(idx)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </React.Fragment>
                                    )}

                                    <Grid item xs={12} style ={{display : (fields.length >=4) ? "none": "block"}}>
                                        <AddIcon 
                                        fontSize='large' 
                                        onClick={() => (fields.length <4) ? setFields([...fields, {"text":"", "type":"text"}]) : null} 
                                        style={{paddingTop: "0.45em"}}
                                        />
                                    </Grid>
                                </Grid>
                                <div className='bottom_form'>
                                    <Button
                                    key="sdfsd"
                                    style={{backgroundColor:"cyan", color:"#3b3b3b"}} 
                                    variant="contained"
                                    onClick={() => setLevel(level-1)}
                                    >
                                        <strong>Back</strong>
                                    </Button>
                                    {" "}
                                    <Button
                                    style={{backgroundColor:"cyan", color:"#3b3b3b"}}
                                    variant="contained"
                                    type="submit"
                                    >
                                        <strong>Continue</strong>
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        case 2:
            return (
                <div 
                className={`make_hello_container ${(showForm) ? "faded" : ""}`} 
                style={{display : (showForm) ? "block" : "none"}}
                >
                    <div className={`invisi_block`}/>
                    <div className={`make_form_container ${(showForm) ? "faded_delay" : ""}`}>
                        <form onSubmit={(e) => {e.preventDefault(); setLevel(level+1)}}>
                        <div style={{width:"100%"}}>
                                <div className="close_icon_wrapper">
                                    <CloseIcon 
                                    sx={{fontSize: "5vh"}} 
                                    className="close_icon" 
                                    onClick={function remove() {showFormFunction(); resetFields();}} />
                                </div>
                            </div>
                            <div className='make_hello_wrapper'>
                                <Typography className='make_title' variant="h2">
                                    Create a Hello Campaign
                                </Typography>
                                <Typography className='make_description' variant='h6'>
                                    Finally, lets write a message to thank your guests for responding.
                                </Typography>
                                <Grid container className='make_hello_form_container '>
                                    <Grid item xs={12}>
                                    <TextareaAutosize 
                                            key={"sakldhasldkh"}
                                            maxLength={"256"}
                                            style={{textAlign: "Left"}}
                                            className="make_hello_auto_resize make_hello_text_box" 
                                            placeholder="Goodbye Message"
                                            name="campaign_goodbye"
                                            value={campaignGoodbye}
                                            onChange={(event) => handleFirstPageChange(event, "campaign_goodbye")}
                                            />
                                    </Grid>
                                </Grid>
                                <div className='bottom_form'>
                                    <Button
                                    style={{backgroundColor:"cyan", color:"#3b3b3b"}} 
                                    variant="contained"
                                    onClick={() => setLevel(level-1)}
                                    >
                                        <strong>Back</strong>
                                    </Button>
                                    {" "}
                                    <Button
                                    style={{backgroundColor:"cyan", color:"#3b3b3b"}} 
                                    variant="contained"
                                    type="submit"
                                    >
                                        <strong>Continue</strong>
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
            case 3:
                return (
                    <div 
                    className={`make_hello_container ${(showForm) ? "faded" : ""}`} 
                    style={{display : (showForm) ? "block" : "none"}}
                    >
                        <div className={`invisi_block`}/>
                        <div className={`make_form_container ${(showForm) ? "faded_delay" : ""}`}>
                            <form onSubmit={(e) => {create_new_hello(e)}}>
                                <div style={{width:"100%"}}>
                                    <div className="close_icon_wrapper">
                                        <CloseIcon sx={{fontSize: "5vh"}} 
                                        className="close_icon" 
                                        onClick={function remove() {showFormFunction(); resetFields();}} />
                                    </div>
                                </div>
                                <div className='make_hello_wrapper'>
                                { (er !== "") ? (
                                    <Alert 
                                    className='make_error_msg'
                                    onClose={() => {setEr("")}} style={(er) ? {textAlign:"left"} : {display: "none"}} 
                                    severity="error"
                                    >
                                        <AlertTitle>Error</AlertTitle>
                                        {er}
                                    </Alert>) : <></>
                                    }
                                    <Typography className='make_title' variant="h2">
                                        Create a Hello Campaign
                                    </Typography>
                                    <Typography className='make_description' variant='h6'>
                                        Lets double check to make sure we have everything right.
                                    </Typography>
            
                                    <Grid container className='make_hello_form_container verify_page' >
                                        <Hidden only="xs">
                                            <Grid item xs={12}>
                                                <a className='verify_cat'><strong>{campaignName}</strong></a>
                                            </Grid>

                                            <Grid item xs={12} sm={6} style={{marginTop: "2em"}}>
                                                <strong>Question</strong>
                                            </Grid>
                                            <Grid item xs={8} sm={6} style={{marginTop: "2em"}}>
                                                <strong>Type</strong>
                                            </Grid>
                                        </Hidden>
                                        <Grid item className="hide_on_pc" xs={12}>
                                            <a><strong>Prompts:</strong></a>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                                <TextareaAutosize 
                                                maxLength={"128"}
                                                className="make_hello_auto_resize" 
                                                placeholder="Enter a prompt here"
                                                value="What is your name?"
                                                name="text"
                                                disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextareaAutosize 
                                                    maxLength={"128"}
                                                    className="make_hello_auto_resize" 
                                                    placeholder="Enter a prompt here"
                                                    style={{width:"50%"}}
                                                    value="text"
                                                    name="text"
                                                    disabled
                                                    />
                                            </Grid>
            
                                        {fields.map((row_val, idx) => 
                                        <React.Fragment key={idx}>
                                            <Grid item xs={12} sm={6} className="top_of_row">
                                                <TextareaAutosize 
                                                maxLength={"128"}
                                                className="make_hello_auto_resize" 
                                                placeholder="Enter a prompt here"
                                                value={row_val.text}
                                                name="text"
                                                onChange={event => handleChangeInput(idx, event, "text")}
                                                disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextareaAutosize 
                                                    maxLength={"128"}
                                                    className="make_hello_auto_resize" 
                                                    placeholder="Enter a prompt here"
                                                    style={{width:"50%"}}
                                                    value={row_val.type}
                                                    name="text"
                                                    onChange={event => handleChangeInput(idx, event, "text")}
                                                    disabled
                                                    />
                                            </Grid>
                                        </React.Fragment>
                                        )}
                                        {(campaignGreeting) ? (
                                            <>
                                                <Grid item xs={12} style={{paddingTop: "2em", textAlign:"left"}}>
                                                    <a><strong>Greeting Mesage:</strong></a>
                                                </Grid>
                                                <Grid item xs={12}>
                                                        <TextareaAutosize 
                                                        maxLength={"128"}
                                                        className="make_hello_auto_resize" 
                                                        placeholder="Enter a prompt here"
                                                        style={{width:"100%"}}
                                                        value={campaignGreeting}
                                                        name="text"
                                                        disabled
                                                        />
                                                </Grid>
                                            </>)
                                             : <></>}

                                        {(campaignGoodbye) ? (
                                            <>
                                                <Grid item xs={12} style={{paddingTop: "2em", textAlign:"left"}}>
                                                    <a><strong>Goodbye Message:</strong></a>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextareaAutosize 
                                                    maxLength={"128"}
                                                    className="make_hello_auto_resize" 
                                                    placeholder="Enter a prompt here"
                                                    style={{width:"100%"}}
                                                    value={campaignGoodbye}
                                                    name="text"
                                                    disabled
                                                    />
                                                </Grid>
                                            </>
                                        )
                                        : <></>}
                                    </Grid>
                                    <div className='bottom_form'>
                                        <Button
                                        key="sdfsd"
                                        style={{backgroundColor:"cyan", color:"#3b3b3b"}} 
                                        variant="contained"
                                        onClick={() => setLevel(level-1)}
                                        >
                                            <strong>Back</strong>
                                        </Button>
                                        {" "}
                                        <Button
                                        style={{backgroundColor:"cyan", color:"#3b3b3b"}} 
                                        variant="contained"
                                        type="submit"
                                        >
                                            <strong>Submit</strong>
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )

    }
}

export default MakeHello