import React, {useState, useEffect} from 'react'
import {useParams} from "react-router-dom";
import './SubmitPage.css';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Grid from '@mui/material/Grid';
import axios from '../../api/axios';
import { Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';


function SubmitPage() {

    const {hello_id} = useParams();
    const [Err, setErr] = useState("");
    const [isSucc, setSucc] = useState(0);
    const [showSucc, setShowSucc] = useState(false);
    const [layout, setLayout] = useState({})
    const [fields, setFields] = useState({})

    function handleFieldChange(idx, event, name){
        const vals = [...fields]
        if (name === "text"){
            vals[idx]["value"] = event.target.value
            setFields(vals)
        } else if (name === "image"){
            console.log(event.target.files[0])
            if (event.target.files[0]?.size > 4968958 ){
                setErr("File is too large, make sure your file is less than 5mb")
            }else{
                vals[idx]["value"] = event.target.files[0]
                setFields(vals)
            }
        }
    }

    useEffect(()=>{
        axios.get(`/getCampaign/${hello_id}`).then((resp)=>{
            setLayout(resp?.data)
            const temp_fields = []
            for(var i = 0; i< resp?.data?.fields.length; i++){
                if (resp?.data?.fields[i]["type"] === "text"){
                    temp_fields.push({"text": resp?.data?.fields[i]["text"], "value": "", "index": i, "type":"text"})
                }
            }
            for(var i = 0; i< resp?.data?.fields.length; i++){
                if (resp?.data?.fields[i]["type"] === "image"){
                    temp_fields.push({"text": resp?.data?.fields[i]["text"], "value": "", "index": i, "type":"image"})
                }
            }
            setFields(temp_fields)
            console.log(temp_fields)
        }).catch((err)=> {
            console.log(err)
        })
    },[])

    const handleSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData();
      for(var i = 0; i <fields.length; i++){
        formData.append(fields[i].index, fields[i].value)
      }
      if (isSucc){
        setShowSucc(true)
        setSucc(isSucc+1)
        return null;
      }else{
        try{
            var response = await axios.post(`/sendHello/${hello_id}`, formData,
            {
                headers: {crossDomain: true}
            })
            if (response) {
                setSucc(isSucc+1)
                setShowSucc(true)
            }else {
                setErr(true)
            }
        }catch (err){
            setErr(true)
          }
        }
      
    };

    const SuccessMessage = () => {
        return (
            <div className='submit_container success_message'>
                <p className='submit_title'>Success!</p>
                <p className='submit_greeting'>
                    <strong>
                        Your submission has been successfully saved. If you need to send another, simply reload this page. 
                    </strong>         
                </p>
                <p className='submit_greeting'>
                    {(layout?.goodbye !== "") ? layout?.goodbye : ""}
                </p>
            </div>
        )
    }
    
    return (
        <>
        { (layout?.fields) ? (
            (isSucc) ? <SuccessMessage /> : <>
                <div className='submit_container'>
                    <Alert 
                    className='error_msg'
                    onClose={() => {setErr("")}} style={(Err !== "") ? {textAlign:"left"} : {display: "none"}} 
                    severity="error"
                    >
                        <AlertTitle>Error</AlertTitle>
                        {Err}
                    </Alert>
                    <p className='submit_title'>{layout?.name}</p>
                    <p className='submit_greeting'>
                        {layout?.greeting}            
                    </p>
                    <div className='submit_container'>
                        <form onSubmit={handleSubmit}>
                            <Grid container className='submit_form_container'>
                                {fields.map((field, i) => 
                                <React.Fragment key={i}>
                                    {(field.type === "text") ?
                                (<>
                                        <Grid item xs={12} sm={6} className="submit_field submit_content">
                                            <div className='submit_prompt'><strong>{field.text}</strong></div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="submit_content">
                                            <TextareaAutosize 
                                            id={`field${i}`} 
                                            value={field.value} 
                                            onChange={(e) => handleFieldChange(i, e, "text")} 
                                            className='submit_text_resize'
                                            required={(i===0)}
                                            maxLength={"256"}
                                            />
                                        </Grid>
                                    </>) :
                                    (
                                        <>
                                        <Grid item xs={12} sm={6}className="submit_field">
                                            <div className='submit_prompt'><strong>{field.text}</strong></div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="submit_content">
                                        <label htmlFor={`field${i}`}>
                                            <input 
                                                style={{"display":"none"}} 
                                                name="image" 
                                                accept="image/*" 
                                                id={`field${i}`}
                                                type="file"
                                                onChange={(e) => handleFieldChange(i, e, "image")}
                                                />
                                                <Button className="submit_field_button"  variant="contained" component="span">
                                                    <strong>{`${(fields[i].value !== "") ? 'Change' : "Select"} Image`}</strong>
                                                </Button>
                                            </label>
                                            {(fields[i].value !== "") ? <Typography>{fields[i].value?.name}</Typography> : <></>}
                                        </Grid>
                                        </>
                                    )}
                                </React.Fragment>
                            )}
                                <Grid item xs={12} style={{"marginTop":"3vh"}}>
                                    <Button type='submit' style={{backgroundColor:"cyan", color:"#3b3b3b"}} variant="contained">
                                        <SendIcon/>{" "}<strong>Send Hello</strong>
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </>
        ) : (<><div className="loader-container"><div className="spinner"></div></div></>)
        }
        </>
    )
}

export default SubmitPage