import React from "react";
import { useNavigate } from "react-router-dom";

import "./Footer.css"
  
const Footer = () => {
  return (
    <div className="box">
      <div className="container">
        <div className="row">
                <a className="footerText">Made with love by:</a>
                <br/>
                <a className="footerLink" href="https://www.seanpmangan.com">{"Sean Mangan "}</a>
                <a className="footerText">and</a>
                <a className="footerLink" href="https://www.lexunwin.com">{" Lex Unwin"}</a>
                <br/>
                <a className="footerLink" href='https://www.buymeacoffee.com/seanpmangan'><strong>{"Buy Us a Coffee"}</strong></a>.
        </div>
      </div>
    </div>
  );
};

export default Footer;