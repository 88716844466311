import React, {useState, useEffect} from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Hidden, TextareaAutosize, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import "./Campaigns.css"
import CopyButton from '../../components/CopyButton/CopyButton';

function Campaigns() {

    const [campaigns, setCampaigns] = useState([])
    const [loaded, setLoaded] = useState(false)
    const axiosPrivate = useAxiosPrivate()

    const fetchData = () => {
        const resp = axiosPrivate.get("/campaigns").then((response) =>{
            setCampaigns(response?.data?.data)
            setLoaded(true)
        }).catch((err)=>{
            console.log(err)
            //window.location.href = "/"
        })
    }

    function delete_campaign(hello_id, idx){
        axiosPrivate.delete(`/campaign/${hello_id}`).then((response) =>{
            console.log(response?.data)
            const vals = [...campaigns]
            vals.splice(idx,1)
            setCampaigns(vals)
        }).catch((err)=>{
            console.log(err)
        })
    }

    useEffect(() => {
        fetchData()
      }, [])

  return (
    <>
      { (!loaded) ? <><div className="loader-container"><div className="spinner"></div></div></> 
      :
        <div className='campaign_wrapper'>
            <p className='campaign_title'>
                Campaigns
            </p>
            <p className='campaign_description'>
                {`Here is a collection of all campaigns you have made so far. You can see details about each campaign you have active and can delete campaigns that are no longer in use.`}
            </p>
            <p style={{textAlign:"center", fontSize: "2vh"}}><strong>{`You currently have ${campaigns.length}/5 active campaigns`}</strong></p>
            {campaigns.map((campaign, i) => {
                return(
                <div key={campaign["hello_id"]}>
                    <Grid container>
                        <Grid item xs={1}>
                        <DeleteIcon 
                        sx={{ fontSize: 30 }}
                        style={{paddingTop:"0.35em" }}
                        onClick={() => delete_campaign(campaign['hello_id'], i)}/>
                        </Grid>
                        <Grid item xs={11}>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography><strong>{`${i+1}. ${campaign["name"]}`}</strong></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Grid container className='campaign_form_container campaign_verify_page' >
                                    <Grid item xs={12}>
                                    </Grid>
                                    <Hidden only="xs">
                                        <Grid item xs={12}>
                                            <a className='campaign_verify_cat'><strong>{campaign["name"]}</strong></a>
                                        </Grid>

                                        <Grid item xs={12} sm={6} style={{marginTop: "2em"}}>
                                            <strong>Question</strong>
                                        </Grid>
                                        <Grid item xs={8} sm={6} style={{marginTop: "2em"}}>
                                            <strong>Type</strong>
                                        </Grid>
                                    </Hidden>
                                    <Hidden smUp>
                                        <Grid item xs={12}>
                                            <a><strong>Prompts:</strong></a>
                                        </Grid>
                                    </Hidden>
                                    {campaign?.fields.map((row_val, idx) => 
                                    <React.Fragment key={idx}>
                                        <Grid item xs={12} sm={6} className="campaign_top_of_row">
                                            <TextareaAutosize 
                                            maxLength={"128"}
                                            className="campaign_auto_resize_disabled" 
                                            placeholder="Enter a prompt here"
                                            value={row_val.text}
                                            name="text"
                                            disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div style={{margin: "auto", textAlign:"center"}}>
                                            <TextareaAutosize 
                                                maxLength={"128"}
                                                className="campaign_auto_resize_disabled" 
                                                placeholder="Enter a prompt here"
                                                style={{width:"50%"}}
                                                value={row_val.type}
                                                name="text"
                                                disabled
                                                />
                                            </div>
                                        </Grid>
                                    </React.Fragment>
                                    )}
                                    {(campaign?.greeting) ? (
                                        <>
                                            <Grid item xs={12} style={{paddingTop: "2em", textAlign:"left"}}>
                                                <a><strong>Greeting Mesage:</strong></a>
                                            </Grid>
                                            <Grid item xs={12}>
                                                    <TextareaAutosize 
                                                    maxLength={"128"}
                                                    className="campaign_auto_resize" 
                                                    placeholder="Enter a prompt here"
                                                    style={{width:"100%"}}
                                                    value={campaign?.greeting}
                                                    name="text"
                                                    disabled
                                                    />
                                            </Grid>
                                        </>)
                                            : <></>}

                                    {(campaign?.goodbye) ? (
                                        <>
                                            <Grid item xs={12} style={{paddingTop: "2em", textAlign:"left"}}>
                                                <a><strong>Goodbye Message:</strong></a>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextareaAutosize 
                                                maxLength={"128"}
                                                className="campaign_auto_resize" 
                                                placeholder="Enter a prompt here"
                                                style={{width:"100%"}}
                                                value={campaign["goodbye"]}
                                                name="text"
                                                disabled
                                                />
                                            </Grid>
                                        </>
                                    )
                                    : <></>}
                                </Grid>
                                    <div className='campaign_see_submissions'>
                                        <CopyButton key="fsd" text={"Share"} url={`https://www.alwayshello.com/submit/${campaign['hello_id']}`}/>
                                        {" "}
                                        <Button
                                        style={{backgroundColor:"cyan", color:"#3b3b3b"}} 
                                        variant="contained"
                                        type="submit"
                                        onClick={() => window.location.href=`/campaign/${campaign['hello_id']}`}
                                        >
                                            <FormatListBulletedIcon/>
                                            <strong>Submissions</strong>
                                        </Button>
                                    </div>                         
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </div>
                )
            })}
        </div>
    }
    <div className='campaign_description'>
        <p style={{paddingTop:"1em", margin:"0"}}>
        {"Always Hello is Ad-Free and I would love to keep it that way. So if you use the site and enjoy it, consider buying me a cup of coffee here "}
        <a href='https://www.buymeacoffee.com/seanpmangan'><strong className='linker'>{"Buy Me a Coffee"}</strong></a>.
        </p>
    </div>
    </>
  )
}

export default Campaigns