import './App.css';
import React, { useEffect, useState } from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from './pages/home/Home';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import SubmitPage from './pages/submitpage/SubmitPage';
import MakeHello from './components/makeHello/MakeHello';
import Login from './components/login/Login';
import useRefreshToken from './hooks/useRefreshToken';
import Campaigns from './pages/campaigns/Campaigns';
import Campaign from './pages/campaign/Campaign';
import Contact from './pages/contact/contact';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import NotFound from './pages/NotFound/NotFound';

function App() {

  const refresh = useRefreshToken()
  const [showForm, setShowForm] = useState(false)
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [initialRun, setInitialRun] = useState(false)

  function former() {
    setShowForm(!showForm)
  }


  function login_toggle() {
    setLoggedIn(!isLoggedIn)
  }

  useEffect(() => {
    const resp = refresh().then(() => { 
      setLoggedIn(true)
      setInitialRun(true)
    }).catch(() => {
      setLoggedIn(false)
      setInitialRun(true)
    })
  }, [])

  return (
    <>
      { (initialRun) ?
        (<>
          <Header showFormFunction={former} isLoggedIn={isLoggedIn} showLogin={login_toggle}/>
          <MakeHello showFormFunction={former} showForm={showForm}/>
          <div style={{minHeight: "calc(100vh - 80px)"}}>
            <Router>
              <Routes>
                <Route path='/' exact element={<Home/>}/>
                <Route path='/submit/:hello_id/' exact element={<SubmitPage/>}/>
                <Route path='/login' exact element={<Login showLogin={login_toggle} />}/>
                <Route path='/campaigns' exact element={<Campaigns/>}/>
                <Route path='/campaign/:hello_id/' exact element={<Campaign/>}/>
                <Route path='/contact/' exact element={<Contact/>}/>
                <Route path='/reset/:reset_id' exact element={<PasswordReset/>}/>
                <Route path='*' element={<NotFound/>}/>
              </Routes>
            </Router>
          </div>
          <Footer/>
        </>)
        : <></>
      }
    </>
  );
}

export default App;
