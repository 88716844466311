import React from 'react'
import './Home.css'
import Grid from '@mui/material/Grid';
import { Paper, Box, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import FolderIcon from '@mui/icons-material/Folder';
import CelebrationIcon from '@mui/icons-material/Celebration';
import CakeIcon from '@mui/icons-material/Cake';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SchoolIcon from '@mui/icons-material/School';

function Home() {
  return (
    <div>
      <div className='home_logo'>
        <div className='color_banner slide_left' style={{backgroundColor: "lightpink"}}>
          <a className="logo_text top_text">Share Memories</a>
        </div>
        <div className='color_banner slide_right' style={{backgroundColor: "lightblue"}}>
          <a className="logo_text bottom_text">Say Hello</a>
        </div>
      </div>
      <div className='home_body'>
        <Grid container spacing={2} className="home_grid_container">
          <Grid item xs={12} md={6.5} style={{textAlign:"center"}}>
            <img  className="home_image" src="https://media1.popsugar-assets.com/files/thumbor/3o3ksm020p_BG7tYNMBepTEne38/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/03/13/746/n/46795010/tmp_6g8oGq_82e2a1c178d3db4e_GettyImages-666653559.jpg" />       
          </Grid>
          <Grid item xs={12} md={5.5} className="overview_wrapper">
            <Paper elevation={10} className="home_about">
              <p className='overview_text'>When hosting a special event it is important to just enjoy the moment. Let us handle keeping your memories safe and accessible.</p>
              <p className='overview_text'><strong>Keep your special day safe forever</strong></p>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper elevation={12} className="how_to" style={{marginTop: "3em"}}>
              <p className='overview_text'>
                <strong>What do we do?</strong>
              </p>
              <p className='overview_text'>
                Always Hello is an easy way to bring together friends and family for any event including;
              </p>
              <Box style={{maxWidth: "200px", margin:"auto"}}>
                  <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <CelebrationIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Weddings"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <SchoolIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Graduations"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <CakeIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Birthdays"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <FavoriteIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Funerals"
                        />
                      </ListItem>
                  </List>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              style={{maxWidth: "100%"}}
              className="center"
              src="https://i.pinimg.com/originals/6e/34/f0/6e34f0027ae54a25873e2e07cf0aafb2.gif"/>
          </Grid>
          <Grid item xs={12}>
            <Box className='how_to' style={{paddingBottom:"0.5em", marginBottom: "1em"}}>
              <p className='overview_text' style={{marginTop: "0", paddingTop: "0"}}>
                Always Hello makes it easy to collect notes, images and memories from friends and family for any event.
                After creating a campaign, share the link to your friends and family to start collecting hellos!
                <br/><br/>
                <strong>Simply login and click 'Make Hello' to begin a campaign.</strong>
              </p>
            </Box>
          </Grid>
        </Grid>
      </div>     
    </div>
  )
}

export default Home